import { Button, Flex, Text, Textarea, useToast } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { StaticContext } from "../../context/StaticProvider";
import services from "../Services/Services";

const FlavorPanel = () => {
  const toast = useToast();
  const [buttonLoading, setButtonLoading] = useState({ save: false });
  const { staticData, setStaticData, loading } = useContext(StaticContext);
  const [data, setData] = useState(
    (staticData && staticData.flavors && staticData.flavors.join("\n")) || ""
  );

  useEffect(() => {
    setData(
      (staticData && staticData.flavors && staticData.flavors.join("\n")) || ""
    );
  }, [staticData]);

  const handleSave = async () => {
    setButtonLoading({ save: true });
    try {
      await services.createStaticData(
        "flavors",
        data.split("\n").filter((flavor) => flavor.trim())
      );
      await setStaticData({
        ...staticData,
        flavors: data.split("\n").filter((flavor) => flavor.trim()),
      });
      setData(staticData.flavors.join("\n"));

      toast({
        title: "Data Saved",
        description: "Data has been successfully saved.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error saving data:", error);
      toast({
        title: "Error",
        description: "An error occurred while saving data.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setButtonLoading({ save: false });
    }
  };

  return (
    !loading &&
    staticData && (
      <Flex
        p={4}
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Text>Please add all flavors in new line</Text>
        <Textarea
          name="flavors"
          value={data}
          onChange={(e) => {
            setData(e.target.value);
          }}
          rows={10}
          width={"50%"}
        />

        <Flex justifyContent={"center"}>
          <Button
            colorScheme="green"
            onClick={handleSave}
            m={4}
            isLoading={buttonLoading.save}
          >
            Save
          </Button>
        </Flex>
      </Flex>
    )
  );
};

export default FlavorPanel;
