import { InfoIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Image,
  Text,
  Tooltip,
  useBreakpointValue,
} from "@chakra-ui/react";
import ButtonPanel from "./ButtonPanel";
import TruncatedDescription from "./TrucncatedDescrption";

const ProductDetails = ({
  styles,
  product,
  staticData,
  loading,
  showOrderDiv,
  setModalOpen,
  subComponentRef,
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Box py={4} px={6} mt={styles.mt} borderRadius="md" bg="gray.50">
      <FormControl mb={4}>
        <FormLabel fontSize="xl" color={"gray.600"}>
          <Flex verticalAlign={"center"}>
            <Image
              src="/assets/icons/pureveg.png"
              width={5}
              height={5}
              my={"auto"}
              mr={4}
            />
            {product.name}
          </Flex>
        </FormLabel>
      </FormControl>
      <hr />

      <FormControl mb={4}>
        <Flex align="center" justify="start">
          <Text fontSize="md" mb={0}>
            ₹
          </Text>
          <Text fontSize="5xl" ml={2} mb={0}>
            {isNaN(
              Math.min(
                ...Object.values(product.prices)
                  .filter((v) => !isNaN(v))
                  .map((v) => parseInt(v))
              )
            )
              ? "Price will be informed during order"
              : Math.min(
                  ...Object.values(product.prices)
                    .filter((v) => !isNaN(v))
                    .map((v) => parseInt(v))
                )}
          </Text>

          <Text color="red" fontWeight="bold" fontSize="xl" mr={2}>
            *
          </Text>
          <Text>
            <Tooltip
              label={
                <>
                  <h6>Price Details</h6>
                  <ul>
                    <li>This is the starting price</li>
                    <li>
                      Estimated price will be calculated based on the weight /
                      size.
                    </li>
                  </ul>
                </>
              }
              aria-label="A tooltip"
              bg="gray.50"
              color="black"
              boxShadow="md"
              borderRadius="lg"
              p={3}
              maxWidth="80vw"
              hasArrow
            >
              <IconButton
                icon={<InfoIcon />}
                variant="ghost"
                size="sm"
                aria-label="Info"
              />
            </Tooltip>
          </Text>
          <Text fontSize="sm" ml={2}>
            <Badge colorScheme="green">Inclusive of all taxes</Badge>
          </Text>
        </Flex>
      </FormControl>

      <FormControl mb={4}>
        <Text fontSize="sm" color="gray.600">
          Please preorder at least{" "}
          <strong>{product.minPreOrderDays} days</strong> before the event.
        </Text>
      </FormControl>
      <div ref={subComponentRef}>
        {!isMobile && (
          <ButtonPanel
            loading={loading}
            showOrderDiv={showOrderDiv}
            setModalOpen={setModalOpen}
          />
        )}
      </div>
      <hr />
      <FormControl mb={4}>
        <FormLabel fontSize="lg" fontWeight={"bold"}>
          Product Details
        </FormLabel>
        <TruncatedDescription
          description={product.description}
          maxLength={50}
        />
      </FormControl>

      <FormControl mb={4}>
        <FormLabel fontSize="sm" mb={2}>
          Ingredients:
        </FormLabel>
        <Flex wrap="wrap">
          {product &&
            product.ingredients &&
            product.ingredients.map((ingredient, index) => (
              <Badge
                key={index}
                colorScheme="blue"
                borderRadius="full"
                px={2}
                py={1}
                fontSize="0.5em" // Slightly larger for better visibility
                mr={2} // Add margin between badges
                mb={2} // Add margin between rows
              >
                {ingredient}
              </Badge>
            ))}
        </Flex>
      </FormControl>

      <FormControl>
        <FormLabel fontSize="sm" mb={2}>
          Flavors Available:
        </FormLabel>
        <Flex wrap="wrap">
          {staticData &&
            staticData.flavors &&
            staticData.flavors.map((flavor, index) => (
              <Badge
                key={index}
                colorScheme="purple"
                borderRadius="full"
                px={2}
                py={1}
                fontSize="0.5em" // Slightly larger for better visibility
                mr={1} // Add margin between badges
                mb={2}
              >
                {flavor.split("$#")[0]}
              </Badge>
            ))}
        </Flex>
      </FormControl>
    </Box>
  );
};

export default ProductDetails;
