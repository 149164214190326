import {
  Button,
  Center,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  StackDivider,
  useToast,
  VStack,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useContext, useState } from "react";
import setCurrentUser from "../../config/setCurrentUser";

import { AuthContext } from "../../context/AuthProvider";

const Login = () => {
  const { login } = useContext(AuthContext);
  // States
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [otp, setOtp] = useState();
  const [buttonText, setButtonText] = useState("Send OTP");
  const [show, setShow] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  //Hooks
  const toast = useToast();

  // Handlers
  const usernameHandler = (username) => {
    setUsername(username);
  };

  const passwordHandler = (password) => {
    setPassword(password);
  };

  const otpHandler = (otp) => {
    setOtp(otp);
  };

  const flip = () => {
    setShow(!show);
  };

  const submitHandler = async (e) => {
    setDisableButton(true);
    let errorMsg = [];
    try {
      if (!username || !username.trim()) {
        errorMsg.push("Email is empty");
      }
      if (!password || !password.trim()) {
        errorMsg.push("password is empty");
      }

      if (errorMsg.length > 0) {
        throw new Error();
      } else {
        toast({
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "bottom",
          title: "Validated Successfully",
        });
      }

      // Call the auth API
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      const { data } = await axios.post(
        `/api/user/auth`,
        { username, password, otp },
        config
      );

      toast({
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom",
        title: data.message,
      });

      setButtonText("Login");

      if (data.token) {
        login(data.token);
      }
      setCurrentUser(data);
    } catch (e) {
      if (e.name === "AxiosError") errorMsg.push(e?.response?.data?.message);
      for (let errorM of errorMsg) {
        toast({
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "bottom",
          title: errorM,
        });
      }
    } finally {
      setOtp("");
      setDisableButton(false);
    }
  };

  return (
    <Center m={"10%"}>
      <VStack
        divider={<StackDivider borderColor="gray.200" />}
        spacing={3}
        width={{ sm: "90%", md: "40%" }}
      >
        {/* <GoogleAuth id="signInBox" label="signin_with" /> */}
        <FormControl id="login-email" isRequired>
          <FormLabel>Username</FormLabel>
          <Input
            type="text"
            onChange={(e) => {
              usernameHandler(e.target.value);
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                document.getElementById("login-password").focus();
              }
            }}
          />
        </FormControl>

        <FormControl id="login-password" isRequired>
          <FormLabel>Password</FormLabel>
          <InputGroup>
            <Input
              type={show ? "text" : "password"}
              onChange={(e) => {
                passwordHandler(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  submitHandler(e);
                }
              }}
            />
            <InputRightElement>
              <Button
                padding={0}
                height="0px"
                marginRight="20px"
                onClick={flip}
              >
                {show ? "Hide" : "Show"}
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>

        <FormControl id="login-otp">
          <FormLabel>OTP</FormLabel>
          <Input
            type="password"
            onChange={(e) => {
              otpHandler(e.target.value);
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                submitHandler(e);
              }
            }}
          />
        </FormControl>

        <FormControl>
          <Button
            type="submit"
            value="Login"
            color="white"
            backgroundColor="#449"
            onClick={(e) => {
              submitHandler(e);
            }}
            width="100%"
            disabled={disableButton}
          >
            {buttonText}
          </Button>
        </FormControl>
      </VStack>
    </Center>
  );
};

export default Login;
