import { ArrowDownIcon, ArrowUpIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Image,
  Input,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import services from "../Services/Services";

const ImageManager = () => {
  const [images, setImages] = useState([]);
  const [newImage, setNewImage] = useState({ keyword: "", data: "", url: "" });
  const toast = useToast();
  const [buttonLoading, setButtonLoading] = useState({
    add: false,
    save: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      const data = await services.fetchBanners();
      setImages(data);
    };

    fetchData();
  }, []);

  const handleAddImage = async () => {
    setButtonLoading({ ...buttonLoading, add: true });
    try {
      const newImageId = "";
      const newImageEntry = {
        imageId: newImageId,
        keyword: newImage.keyword,
        data: newImage.data,
      };
      setImages([...images, newImageEntry]);
      setNewImage({ keyword: "", data: "" });
    } catch (error) {
      console.log(error);
    } finally {
      setButtonLoading({ ...buttonLoading, add: false });
    }
  };

  const handleImageChange = async (e) => {
    setButtonLoading({ ...buttonLoading, add: true });
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setNewImage({ ...newImage, data: reader.result });
    };
    reader.readAsDataURL(file);
    setButtonLoading({ ...buttonLoading, add: false });
  };

  const moveImage = (index, direction) => {
    const newImages = [...images];
    const [removedImage] = newImages.splice(index, 1);
    newImages.splice(index + direction, 0, removedImage);
    setImages(newImages);
  };

  const deleteImage = (index) => {
    const newImages = images.filter((_, i) => i !== index);
    setImages(newImages);
  };

  const handleSaveAll = async () => {
    console.log(images);
    setButtonLoading({ ...buttonLoading, save: true });
    try {
      const response = await services.saveBanners(images);
      console.log(response);

      toast({
        title: "Data Saved",
        description: "Data has been successfully saved.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      console.error("Error saving banners:", err);
      toast({
        title: "Error",
        description: err.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setButtonLoading({ ...buttonLoading, save: false });
    }
  };

  return (
    <VStack spacing={4} mx={"auto"}>
      {images.map((image, index) => (
        <Box key={index} borderWidth="1px" borderRadius="lg" overflow="hidden">
          <Image
            src={image.data || image.url}
            alt={image.keyword}
            width={"250px"}
            height={"100%"}
            objectFit="cover"
          />
          <Box p="6">
            <Box d="flex" alignItems="baseline">
              <Box fontWeight="semibold" as="h4" lineHeight="tight" isTruncated>
                {image.keyword}
              </Box>
            </Box>
            <HStack mt="2" spacing="2">
              <IconButton
                icon={<ArrowUpIcon />}
                aria-label="Move up"
                onClick={() => moveImage(index, -1)}
                isDisabled={index === 0}
              />
              <IconButton
                icon={<ArrowDownIcon />}
                aria-label="Move down"
                onClick={() => moveImage(index, 1)}
                isDisabled={index === images.length - 1}
              />
              <IconButton
                icon={<DeleteIcon />}
                aria-label="Delete"
                colorScheme="red"
                onClick={() => deleteImage(index)}
              />
            </HStack>
          </Box>
        </Box>
      ))}
      <FormControl id="new-image" width={"50%"} mx="auto">
        <FormLabel>Keyword</FormLabel>
        <Input
          value={newImage.keyword}
          onChange={(e) =>
            setNewImage({ ...newImage, keyword: e.target.value })
          }
          placeholder="Enter keyword"
        />
        <FormLabel mt={4}>Upload Image</FormLabel>
        <Input type="file" onChange={handleImageChange} />
        <Flex justifyContent={"space-between"}>
          <Button
            mt={4}
            onClick={handleAddImage}
            colorScheme="blue"
            isDisabled={!newImage.data || !newImage.keyword}
            isLoading={buttonLoading.add}
          >
            Add Banner
          </Button>
          <Button
            mt={4}
            onClick={handleSaveAll}
            colorScheme={"green"}
            isLoading={buttonLoading.save}
          >
            Save All
          </Button>
        </Flex>
      </FormControl>
    </VStack>
  );
};

export default ImageManager;
