import React, { createContext, useEffect, useState } from "react";
import services from "../components/Services/Services";

// Create a Context for the authentication
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    token: null,
  });
  const loadAuth = async () => {
    const token = localStorage.getItem("token");

    if (token) {
      // Check if the token is valid and not expired
      const data = await services.verifyUser();
      if (data.verified) {
        setAuthState({
          isAuthenticated: true,
          token,
        });
      } else {
        setAuthState({
          isAuthenticated: false,
          token: null,
        });
      }
    } else {
      setAuthState({
        isAuthenticated: false,
        token: null,
      });
    }
  };
  useEffect(() => {
    loadAuth();
  }, []);

  const login = (token) => {
    setAuthState({
      isAuthenticated: true,
      token,
    });
  };

  const logout = () => {
    localStorage.removeItem("token");
    setAuthState({
      isAuthenticated: false,
      token: null,
    });
  };

  return (
    <AuthContext.Provider value={{ authState, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
