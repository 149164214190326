import { Center, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  askNotificationPermission,
  subscribeUserToPush,
} from "../../push-notifications";
import services from "../Services/Services";
import OrderTable from "./OrderTable";

const Orders = () => {
  useEffect(() => {
    try {
      askNotificationPermission().then(subscribeUserToPush);
    } catch (error) {}
  }, []);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const loadData = async () => {
    setLoading(true);
    try {
      const res = await services.fetchOrders();
      setData(res);
      console.log(res);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  if (loading) {
    return (
      <Center height="60vh">
        <Spinner size="xl" />
      </Center>
    );
  }
  return data && <OrderTable data={data} setData={setData} />;
};

export default Orders;
