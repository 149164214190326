import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
} from "@chakra-ui/react";

import DatePicker from "react-datepicker";

const OrderDetailsPanel = ({
  isMobile,
  estimated,
  handleChanges,
  product,
  formData,
  staticData,
  tomorrow,
  setModalOpen,
}) => {
  return (
    <Flex
      direction="column"
      p={4}
      bg="white"
      width="100%"
      alignItems="center"
      height={"70vh"}
      overflowY="auto"
      borderTopRadius={"lg"}
      border={"1px solid #ddd"}
      boxShadow={"2xl"}
    >
      <Flex width={isMobile ? "100%" : "50%"} direction="column">
        <Flex justifyContent={"right"} cursor={"pointer"}>
          <CloseIcon
            onClick={() => {
              setModalOpen("A", false);
              setModalOpen("B", false);
            }}
          />
        </Flex>
        <Text fontSize="md" fontWeight="bold" mb={4}>
          Estimated Price: {estimated}
          <span style={{ color: "red" }}>*</span>
        </Text>
        <FormControl mb={4}>
          <FormLabel fontSize="md" fontWeight="bold">
            Weight
          </FormLabel>
          <Select
            name="weight"
            onChange={(e) => handleChanges("weight", e.target.value)}
          >
            {product &&
              product.prices &&
              Object.keys(product.prices).map((weight, index) => (
                <option
                  key={index}
                  value={weight + "$#" + product.prices[weight]}
                >
                  {weight} {isNaN(product.prices[weight]) ? "" : "@ ₹"}
                  {product.prices[weight]}{" "}
                  {isNaN(product.prices[weight]) ? "" : " Only"}
                </option>
              ))}
          </Select>
        </FormControl>
        <FormControl mb={4}>
          <FormLabel fontSize="md" fontWeight="bold">
            Quantity
          </FormLabel>
          <Input
            type="number"
            placeholder="Enter quantity..."
            value={formData.quantity}
            onChange={(e) => handleChanges("quantity", e.target.value)}
            min={1}
          />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel fontSize="md" fontWeight="bold">
            Flavor
          </FormLabel>
          <Select
            name="flavor"
            value={formData.flavor}
            onChange={(e) => handleChanges("flavor", e.target.value)}
          >
            {staticData &&
              staticData.flavors &&
              staticData.flavors.map((flavor, index) => (
                <option key={index} value={flavor}>
                  {flavor.split("$#")[0]}
                </option>
              ))}
          </Select>
        </FormControl>
        <FormControl mb={4}>
          <FormLabel fontSize="md" fontWeight="bold">
            Delivery Date
          </FormLabel>
          <Box borderWidth={1} borderRadius="lg" p={2}>
            <DatePicker
              selected={formData.deliveryDt}
              onChange={(date) => handleChanges("deliveryDt", date)}
              showTimeSelect
              dateFormat="Pp"
              colorScheme="primary"
              minDate={tomorrow}
            />
          </Box>
        </FormControl>
        <Flex justifyContent="space-between">
          <Button
            colorScheme="blue"
            onClick={() => {
              setModalOpen("A", false);
              setModalOpen("B", true);
            }}
            width="40%"
          >
            Next
          </Button>
          <Button onClick={() => setModalOpen("A", false)} width="40%">
            Cancel
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default OrderDetailsPanel;
