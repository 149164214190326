import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { StaticContext } from "../../context/StaticProvider";
import services from "../Services/Services";

const emptyRow = "";

const StaticDataPanel = ({ staticDataKey }) => {
  const toast = useToast();
  const [buttonLoading, setButtonLoading] = useState({ save: false });
  const { staticData, setStaticData, loading } = useContext(StaticContext);
  const [rows, setRows] = useState(staticData[staticDataKey] || []);
  useEffect(() => {
    setRows(staticData[staticDataKey] || []);
  }, [staticDataKey]);
  const handleInputChange = (index, value) => {
    const newRows = [...rows];
    newRows[index] = value;
    setRows(newRows);
  };

  const handleAddRow = () => {
    setRows([...rows, emptyRow]);
  };

  const handleDeleteRow = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
  };

  const handleSave = async () => {
    setButtonLoading({ save: true });
    try {
      let data = [...rows];
      data = data.filter((row) => row); // remove empty rows
      data = data.map((row) => row.trim());
      if (data.length === 0) {
        throw new Error("No data provided to save.");
      }
      await services.createStaticData(staticDataKey, data);
      toast({
        title: "Data Saved",
        description: "Data has been successfully saved.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setStaticData({ ...staticData, [staticDataKey]: data });
    } catch (error) {
      console.error("Error saving data:", error);
      toast({
        title: "Error",
        description: "An error occurred while saving data.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setButtonLoading({ save: false });
    }
  };
  console.log(rows);
  return (
    !loading && (
      <Box p={4}>
        <VStack spacing={4} mb={4}>
          {rows.map((row, index) => (
            <Flex key={index} alignItems="center">
              <Input
                placeholder="Add Value"
                value={row}
                onChange={(e) => handleInputChange(index, e.target.value)}
                mr={2}
              />

              <IconButton
                onClick={() => handleDeleteRow(index)}
                icon={<DeleteIcon />}
                color={"red"}
              >
                Delete
              </IconButton>
            </Flex>
          ))}
        </VStack>
        <Flex justifyContent={"center"}>
          <Button onClick={handleAddRow} mr={2} colorScheme="blue">
            Add Row
          </Button>
          <Button
            onClick={handleSave}
            isLoading={buttonLoading.save}
            colorScheme="green"
          >
            Save
          </Button>
        </Flex>
      </Box>
    )
  );
};

export default StaticDataPanel;
