import {
  Box,
  Center,
  Grid,
  Spinner,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import SingleProduct from "./SingleProduct";

const SearchProducts = () => {
  const columns = useBreakpointValue({ base: 2, md: 5, lg: 6 });
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [headerText, setHeaderText] = useState({ text: "", font: "" });

  const loadData = async ({ productId = "", dropdown = "", keywords = "" }) => {
    setLoading(true);
    try {
      if (productId) {
        setHeaderText({ text: `Product Id: ${productId}`, font: "" });
      } else if (dropdown) {
        setHeaderText({ text: `${dropdown}`, font: "Playwrite FR Moderne" });
      } else if (keywords) {
        setHeaderText({ text: `Search Results for: "${keywords}"`, font: "" });
      }
      console.log("Loading for - ", { productId, dropdown, keywords });
      const response = await axios.get(`/api/admin/products/search`, {
        params: { productId, dropdown, keywords },
      });
      const data = response.data;
      setProducts(data);
    } catch (error) {
      console.error("Error loading JSON file:", error);
    } finally {
      setLoading(false);
    }
  };

  const searchProducts = async () => {
    const productId = searchParams.get("productId");
    const dropdown = searchParams.get("dropdown");
    const keywords = searchParams.get("keywords");
    console.log("searching for ", searchParams);
    loadData({ productId, dropdown, keywords });
  };

  useEffect(() => {
    console.log("Inside SearchProducts.useEffect");
    searchProducts();
  }, [location]);

  const styles = useBreakpointValue({
    base: { mx: 4, my: 2, px: 4, py: 2 },
    md: { mx: 8, my: 4, px: 8, py: 8 },
  });

  if (loading) {
    return (
      <Center height="60vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  if (!products.length) {
    return (
      <Center height="60vh">
        <Text fontSize="xl">No results found</Text>
      </Center>
    );
  }

  return (
    <Box
      key="SearchedProducts"
      px={styles.px}
      py={styles.py}
      my={styles.my}
      borderWidth="1px"
      borderRadius="md"
      boxShadow="md"
      className="mainBox"
    >
      <Text fontSize="2xl" textAlign={"center"} fontFamily={headerText.font}>
        {headerText.text}
      </Text>
      <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={8}>
        {products.map((product) => (
          <SingleProduct product={product} key={product.productId} />
        ))}
      </Grid>
    </Box>
  );
};

export default SearchProducts;
