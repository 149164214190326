import { Flex, Image } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
const Logo = () => {
  const navigate = useNavigate();
  return (
    <Flex>
      <Image
        my={"auto"}
        src="/assets/images/logo/fornaroma_icon.svg"
        className="logo"
        alt="Fornaroma"
        height={"50px"}
        display="inline"
        cursor="pointer"
        onClick={() => navigate("/")}
      />
      <Image
        my={"auto"}
        src="/assets/images/logo/fornaroma_text.svg"
        className="logo"
        alt="Fornaroma"
        height={"25px"}
        display="inline"
        cursor="pointer"
        onClick={() => navigate("/")}
      />
    </Flex>
  );
};

export default Logo;
