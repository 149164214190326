import { Box, Flex, Icon, Text, useBreakpointValue } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { StaticContext } from "../../context/StaticProvider";

const Footer = ({ adminFlag }) => {
  const direction = useBreakpointValue({ base: "column", md: "row" });
  const { staticData, loading } = useContext(StaticContext);
  const [footer, setFooter] = useState(
    (staticData && staticData.footer && JSON.parse(staticData.footer[0])) || {}
  );

  useEffect(() => {
    console.log("Footer, useEffect");
    setFooter(
      (staticData && staticData.footer && JSON.parse(staticData.footer[0])) ||
        {}
    );
  }, [loading]);
  return (
    !loading &&
    footer && (
      <Box as="footer" py={6} bg="gray.800" color="white">
        <Flex
          direction={"column"}
          maxW="6xl"
          mx="auto"
          px={4}
          textAlign="center"
        >
          {!adminFlag && (
            <>
              <Flex
                align="center"
                justify="space-between"
                padding="1rem"
                direction={direction}
              >
                <Text fontSize="md" mb={2}>
                  Contact Information: <br /> {footer.email1} <br />
                  {/* Settings */}
                  {footer.email2}
                </Text>
                <Text fontSize="md" mb={2}>
                  Phone: <br />
                  {footer.phone1}
                  <br />
                  {footer.phone2}
                </Text>
                <Text fontSize="md" mb={2}>
                  Follow us on: <br />{" "}
                  {footer.instagram && (
                    <a href={footer.instagram}>
                      <Icon as={FaInstagram} boxSize={6} /> Instagram
                    </a>
                  )}
                  <br />{" "}
                  {footer.facebook && (
                    <a href={footer.facebook}>
                      <Icon as={FaFacebook} boxSize={6} /> Facebook
                    </a>
                  )}
                </Text>
              </Flex>
              <Text fontSize="sm" mb={2}>
                {/* Settings */}
                {footer.footerText}
              </Text>
            </>
          )}
          <Text fontSize="md" mb={2}>
            © 2024 Fornaroma. All rights reserved.{" "}
            {adminFlag &&
              "Make sure you have permission to view this page. Unauthorized access to this area is prohibited and may be offensive. Please navigate back to the main site or contact the administrator if you believe this is an error."}
          </Text>
        </Flex>
      </Box>
    )
  );
};

export default Footer;
