import { Box } from "@chakra-ui/react";
import AllProducts from "./AllProducts";
import BannerPanel from "./BannerPanel";
import FlavorPanel from "./FlavorPanel";
import LOVPanel from "./LOVPanel";
import MenuPanel from "./MenuPanel";
import Orders from "./Orders";
import SiteSettingPanel from "./SiteSettingPanel";
import StaticDataPanel from "./StaticDataPanel";

const TabContent = ({ title }) => {
  return (
    <Box>
      {title === "Quantity" && <StaticDataPanel staticDataKey={"quantities"} />}
      {title === "Products" && <AllProducts type="all" />}
      {title === "Banners" && <BannerPanel />}
      {title === "Menu Headers" && <MenuPanel />}
      {title === "Site Settings" && <SiteSettingPanel />}
      {title === "Flavors" && <FlavorPanel />}
      {title === "LOVs" && <LOVPanel />}
      {title === "Orders" && <Orders />}
    </Box>
  );
};

export default TabContent;
