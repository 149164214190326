import { Box, Center, Image, Spinner } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import services from "../Services/Services";

const SwiperCollection = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const fetchImages = async () => {
      try {
        const response = await services.fetchBanners();
        setImages(response);
      } catch (error) {
        console.error("Error loading images:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchImages();
  }, []);

  const handleClick = (image) => {
    navigate(`/search?keywords=${image.keyword}`);
  };

  return !loading && images ? (
    <Box mx="auto" py={4} px={1} borderRadius="lg" boxShadow="md" bg="white">
      <Swiper
        rewind={true}
        spaceBetween={5}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination, Navigation]}
        breakpoints={{
          1024: {
            slidesPerView: 4,
            spaceBetween: 5,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 5,
          },
          480: {
            slidesPerView: 1,
            spaceBetween: 5,
          },
        }}
      >
        {images &&
          images.map((image, index) => (
            <SwiperSlide key={index}>
              <Center m={4} height={"200px"}>
                <Image
                  src={image.url}
                  alt={`Banner ${index}`}
                  borderRadius="lg"
                  boxShadow="lg"
                  height={"100%"}
                  width={"100%"}
                  onClick={(e) => {
                    handleClick(image);
                  }}
                />
              </Center>
            </SwiperSlide>
          ))}
      </Swiper>
    </Box>
  ) : (
    <Center height="60vh">
      <Spinner size="xl" />
    </Center>
  );
};

export default SwiperCollection;
