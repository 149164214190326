import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import TabContent from "./TabContent";

const Panel = () => {
  const tabs = [
    "Site Settings",
    "Banners",
    "Menu Headers",
    "Products",
    "Quantity",
    "Flavors",
    "LOVs",
    "Orders",
  ];
  const [activeTab, setActiveTab] = useState("Orders");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setIsDrawerOpen(false); // Close the drawer after selecting a tab on mobile
  };

  return (
    <Box>
      <Flex width={"100%"}>
        <IconButton
          icon={<HamburgerIcon />}
          aria-label="Open menu"
          onClick={() => setIsDrawerOpen(true)}
          ml={4}
          mr={4}
          mt={2}
        />

        <Drawer
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          placement="left"
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Admin Panel</DrawerHeader>
            <DrawerBody>
              <VStack spacing={3}>
                {tabs.map((tab) => (
                  <Button
                    key={tab}
                    variant={activeTab === tab ? "solid" : "ghost"}
                    onClick={() => handleTabClick(tab)}
                    width={"100%"}
                  >
                    {tab}
                  </Button>
                ))}
              </VStack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        <Box textAlign={"center"} width={"100%"} p={2} my={2}>
          <Text fontSize="2xl" align={"center"}>
            {activeTab}
          </Text>
        </Box>
      </Flex>
      <Box
        p={2}
        width={"100%"}
        boxShadow={"md"}
        borderWidth={1}
        borderRadius={"md"}
      >
        <TabContent title={activeTab} />
      </Box>
    </Box>
  );
};

export default Panel;
