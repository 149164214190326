import { Box } from "@chakra-ui/react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import AdminPage from "./Pages/AdminPage";
import CustomerFront from "./Pages/CustomerFront";
import ErrorPage from "./Pages/ErrorPage";
import { AuthProvider } from "./context/AuthProvider";
function App() {
  return (
    <Box className="App">
      <AuthProvider>
        <Routes>
          <Route path="/" element={<CustomerFront mode="home" />} exact />
          <Route path="/home" element={<CustomerFront mode="home" />} exact />
          <Route path="/view" element={<CustomerFront mode="view" />} exact />
          <Route
            path="/search"
            element={<CustomerFront mode="search" />}
            exact
          />
          <Route
            path="/36b9" //7480-ef58-4208-8195-502873311370"
            element={<AdminPage />}
            exact
          />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </AuthProvider>
    </Box>
  );
}

export default App;
