import React, { createContext, useEffect, useState } from "react";
import services from "../components/Services/Services";

// Create a Context for the authentication
const StaticContext = createContext();

const StaticProvider = ({ children }) => {
  const [staticData, setStaticData] = useState(null);
  const [loading, setLoading] = useState(true);

  const loadData = async () => {
    setLoading(true);
    try {
      const data = await services.loadStaticData();
      let sData = {};
      for (let d of data) {
        sData[d.lovKey] = d.lovValue;
      }
      setStaticData(sData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  return (
    <StaticContext.Provider value={{ staticData, setStaticData, loading }}>
      {children}
    </StaticContext.Provider>
  );
};

export { StaticContext, StaticProvider };
