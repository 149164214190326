import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
} from "@chakra-ui/react";
import React, { useState } from "react";

const CommonInput = ({
  label = "",
  name = "",
  type = "text",
  value = "",
  onChangeHandler,
  required = true,
  options = [],
}) => {
  const [data, setData] = useState(value);

  const handleChange = (e) => {
    setData(e.target.value);
    onChangeHandler(e);
  };

  const renderFormControl = () => {
    switch (type) {
      case "textarea":
        return <Textarea name={name} value={data} onChange={handleChange} />;
      case "select":
        return (
          <Select
            name={name}
            value={data}
            onChange={handleChange}
            placeholder="Select an Option"
          >
            {options.map((opt, index) => (
              <option key={index} value={opt}>
                {opt}
              </option>
            ))}
          </Select>
        );
      default:
        return (
          <Input type={type} name={name} value={data} onChange={handleChange} />
        );
    }
  };
  return (
    <FormControl isRequired={required}>
      <FormLabel>{label}</FormLabel>
      {renderFormControl()}
    </FormControl>
  );
};

export default CommonInput;
