import { Button, Flex } from "@chakra-ui/react";
import ShineButton from "../../Common/ShineButton";

const ButtonPanel = ({ loading, showOrderDiv, setModalOpen }) => {
  return (
    !loading && (
      <Flex justifyContent={"space-around"}>
        <Button w="60%" colorScheme="gray">
          View Similar
        </Button>
        <ShineButton
          w="60%"
          colorScheme="blue"
          onClick={() => {
            setModalOpen("A", true);
          }}
        >
          Order Now
        </ShineButton>
      </Flex>
    )
  );
};

export default ButtonPanel;
