import { Box, Image, Text, useBreakpointValue } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const SingleProduct = ({ product }) => {
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const truncateDescription = (description, maxLength) => {
    if (description.length > maxLength) {
      return description.substring(0, maxLength) + "...";
    }
    return description;
  };
  const height = useBreakpointValue({
    base: { img: "180px", bx: "250px" },
    md: { img: "230px", bx: "300px" },
  });
  return (
    // Settings
    <Box
      key={product.id}
      bgColor={"white"}
      borderWidth="1px"
      borderRadius="lg"
      boxShadow="sm"
      overflow="hidden"
      height={height.bx}
      onClick={(e) => {
        scrollToTop();
        navigate(`/view?productId=${product.productId}`, { state: product });
      }}
    >
      <Box height={height.img} width={"100%"} mx={"auto"}>
        <Image
          src={product?.images?.[0]?.low}
          alt={product.name}
          height="100%"
          width="100%"
          objectFit="cover"
        />
      </Box>
      <Box p="4" bgColor={"white"}>
        <Text fontSize="sm" mb="2">
          {truncateDescription(product.name, 25)}
        </Text>
      </Box>
    </Box>
  );
};

export default SingleProduct;
