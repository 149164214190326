import { Box, Center, Spinner, useBreakpointValue } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { StaticContext } from "../../context/StaticProvider";
import SingleMenu from "./SingleMenu";

const Menubar = () => {
  const styles = useBreakpointValue({
    base: { mx: 4, my: 2, px: 4, py: 2 },
    md: { mx: 8, my: 4, px: 8, py: 2 },
  });
  const { staticData, loading } = useContext(StaticContext);
  const [menu, setMenu] = useState(
    (staticData && staticData.menubar && JSON.parse(staticData.menubar)) || {}
  );
  useEffect(() => {
    console.log("Inside menubar.js useEffect()");
    setMenu(
      (staticData && staticData.menubar && JSON.parse(staticData.menubar)) || {}
    );
  }, [staticData]);

  return !loading && menu ? (
    <Box
      justify="space-between"
      borderRadius={"md"}
      boxShadow={"lg"}
      px={styles.px}
      my={styles.my}
      className="mainBox"
      bgColor={"white"}
      width="100%"
      overflowX="auto"
      whiteSpace="nowrap"
    >
      {menu &&
        Object.keys(menu).map((menuKey) => {
          return (
            <SingleMenu
              key={menuKey}
              menuKey={menuKey}
              menuList={menu[menuKey]}
            />
          );
        })}
    </Box>
  ) : (
    <Center height="60vh">
      <Spinner size="xl" />
    </Center>
  );
};

export default Menubar;
