import services from "./components/Services/Services";

export function askNotificationPermission() {
  return new Promise(function (resolve, reject) {
    const permissionResult = Notification.requestPermission(function (result) {
      resolve(result);
    });

    if (permissionResult) {
      permissionResult.then(resolve, reject);
    }
  }).then(function (permissionResult) {
    if (permissionResult !== "granted") {
      throw new Error("We weren't granted permission.");
    }
  });
}

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export function subscribeUserToPush() {
  navigator.serviceWorker
    .register("/sw.js")
    .catch(function (error) {
      console.error("Failed to subscribe the user 0:", error);
    })
    .then(function (registration) {
      console.log("Service Worker registration successful", registration);
      const subscribeOptions = {
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(
          "BIuVY25IKdED8sYB2INffqpEKs6yrecENYsxj9bxfDBB9BYWUDtXK5vU0c5FiCQNzCXVNfReyZvGD2JxTCkGcjs"
        ),
      };

      return registration.pushManager.subscribe(subscribeOptions);
    })
    .catch(function (error) {
      console.error("Failed to subscribe the user 1:", error);
    })
    .then(function (pushSubscription) {
      console.log(
        "Received PushSubscription:",
        JSON.stringify(pushSubscription)
      );
      services.subscribe(pushSubscription);
      return pushSubscription;
    })
    .catch(function (error) {
      console.error("Failed to subscribe the user:", error);
    });
}
