import {
  Box,
  Flex,
  IconButton,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { StaticContext } from "../../context/StaticProvider";

const FloatingWhatsAppButton = () => {
  const { staticData, loading } = useContext(StaticContext);
  const [number, setNumber] = useState(null);
  const handleWhatsAppClick = () => {
    // Handle WhatsApp button click event
    const message =
      "Hello there! I discovered your contact information on fornaroma.com and I have an inquiry. Could you please assist me?";
    window.open(`https://wa.me/${number}?text=${message}`, "_blank"); // Settings
  };
  const position = useBreakpointValue({
    base: { right: 15, bottom: 55 },
    md: { right: 10, bottom: 5 },
  });

  useEffect(() => {
    if (staticData && staticData.footer) {
      const footer = JSON.parse(staticData.footer);
      setNumber(footer.whatsapp);
    }
  }, [staticData]);
  return (
    !loading && (
      <Flex position="fixed" style={position} zIndex={10}>
        <Box>
          <IconButton
            icon={<FaWhatsapp size={"50px"} />}
            width={"70px"}
            height={"70px"}
            bgColor={"#03fc7f"}
            color={"white"}
            borderRadius={"full"}
            onClick={handleWhatsAppClick}
            aria-label="WhatsApp"
          />
          <Text color="gray.600" fontWeight={"bold"}>
            Contact Us
          </Text>
        </Box>
      </Flex>
    )
  );
};

export default FloatingWhatsAppButton;
