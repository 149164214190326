import {
  Box,
  Center,
  Grid,
  Spinner,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import SingleProduct from "./SingleProduct";

const Products = ({ type }) => {
  const columns = useBreakpointValue({ base: 2, md: 5, lg: 6 });
  const [products, setProducts] = useState(null);
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`/api/admin/products/${type}/get`);
        const data = response.data;
        setProducts(data);
        let headerSet = new Set();
        data.forEach((element) => {
          element.sectionHeaders.forEach((header) => {
            headerSet.add(header);
          });
        });
        let headerList = [];
        headerSet.forEach((header) => headerList.push(header));
        setSections(headerList);
      } catch (error) {
        console.error("Error loading JSON file:", error);
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [type]);

  const styles = useBreakpointValue({
    base: { my: 2, px: 4, py: 2 },
    md: { my: 4, px: 8, py: 8 },
  });

  return sections && !loading ? (
    sections.map((section, index) => {
      return (
        <Box
          key={section}
          px={styles.px}
          py={styles.py}
          mx={styles.mx}
          my={styles.my}
          borderRadius={"lg"}
          boxShadow={"md"}
          className="mainBox"
          bgColor={index % 2 === 0 ? "gray.100" : "white"}
        >
          <Text
            fontSize="xl"
            textAlign={"center"}
            fontFamily={"Playwrite FR Moderne"}
            fontWeight={"bold"}
          >
            {section}
          </Text>
          <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={8}>
            {products &&
              products.map(
                (product, index) =>
                  index < 30 &&
                  product.sectionHeaders.includes(section) && (
                    <SingleProduct product={product} key={product.productId} />
                  )
              )}
          </Grid>
        </Box>
      );
    })
  ) : loading ? (
    <Center height="60vh">
      <Spinner size="xl" />
    </Center>
  ) : (
    <Text fontSize="2xl">Nothing Found</Text>
  );
};

export default Products;
