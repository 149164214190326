import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ScaleFade,
  Text,
} from "@chakra-ui/react";
import React from "react";

const OrderPlacedModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ScaleFade initialScale={0.9} in={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Order Confirmation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box textAlign="center" py={4}>
              <Text fontSize="2xl" fontWeight="bold" mb={2}>
                Yay! Your order has been placed
              </Text>
              <Text>We will contact you soon</Text>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </ScaleFade>
    </Modal>
  );
};

export default OrderPlacedModal;
