import { Switch, VStack } from "@chakra-ui/react";
import React, { useState } from "react";

const Toggler = ({ onChange, value = true }) => {
  const [isChecked, setIsChecked] = useState(value);

  const handleToggle = () => {
    setIsChecked(!isChecked);
    onChange(!isChecked);
  };

  return (
    <VStack spacing={4}>
      <Switch onChange={handleToggle} isChecked={isChecked}>
        {isChecked ? "Enabled" : "Disabled"}
      </Switch>
    </VStack>
  );
};

export default Toggler;
