import { Button } from "@chakra-ui/react";
import React from "react";

// Define a more pronounced shine effect keyframe animation
const shineAnimation = `
  @keyframes shine {
    0% {
      background-position: 200% 0;
    }
    50% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
`;

const ShineButton = ({ children, ...props }) => {
  return (
    <Button
      position="relative"
      overflow="hidden"
      colorScheme="blue"
      _before={{
        content: '""',
        position: "absolute",
        top: 0,
        left: -100,
        width: "300%",
        height: "100%",
        background:
          "linear-gradient(90deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0) 75%)",
        backgroundSize: "200% 100%",
        animation: "shine 5s infinite",
        zIndex: 1,
        opacity: 0.6,
      }}
      sx={{
        "@global": {
          ...shineAnimation,
        },
      }}
      _hover={{
        bg: "blue.500",
        color: "white",
        _before: {
          background:
            "linear-gradient(90deg, rgba(255, 255, 255, 0.6) 25%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.6) 75%)",
        },
      }}
      _active={{
        bg: "blue.600",
      }}
      zIndex={0} // Ensure the shine effect is behind the text
      {...props}
    >
      {children}
    </Button>
  );
};

export default ShineButton;
