import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Image,
  Input,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";

const MultipleImageUploader = ({ onChange, imageList = [] }) => {
  const [images, setImages] = useState(imageList);

  const getImageData = (image) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleImageChange = async (event) => {
    const files = Array.from(event.target.files);

    const newImages = await Promise.all(
      files.map(async (file) => {
        const base64Data = await getImageData(file); // Wait for the promise to resolve
        return {
          imageId: null,
          data: base64Data,
          existing: false,
          low: null,
          medium: null,
        };
      })
    );

    setImages((prevImages) => [...prevImages, ...newImages]);
    onChange([...images, ...newImages]);
  };

  const handleRemoveImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    onChange(images.filter((_, i) => i !== index));
  };

  return (
    <Box p={4} borderWidth={1} borderRadius="md" borderColor="gray.200">
      <VStack spacing={4}>
        <Input
          type="file"
          accept="image/*"
          multiple
          onChange={handleImageChange}
          display="none"
          id="image-uploader"
        />
        <Button as="label" htmlFor="image-uploader" colorScheme="teal">
          Select Images
        </Button>

        <Flex wrap="wrap" justify="center">
          {images.map((image, index) => (
            <Box key={index} position="relative" m={2}>
              <Image
                boxSize="100px"
                objectFit="cover"
                src={image.imageId ? image.low : image.data}
                alt={`Uploaded ${index}`}
                borderRadius="md"
                border="1px solid #ccc"
              />
              <IconButton
                size="sm"
                icon={<CloseIcon />}
                position="absolute"
                top={1}
                right={1}
                onClick={() => handleRemoveImage(index)}
                colorScheme="red"
              />
            </Box>
          ))}
        </Flex>

        {images.length > 0 && (
          <Button
            colorScheme="red"
            onClick={() => {
              setImages([]);
              onChange([]);
            }}
          >
            Remove All
          </Button>
        )}
      </VStack>
    </Box>
  );
};

export default MultipleImageUploader;
