import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  VStack,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { StaticContext } from "../../context/StaticProvider";
import CommonMultiSelect from "../Common/CommonMultiSelect";
import services from "../Services/Services";

const MenuPanel = () => {
  const toast = useToast();
  const [buttonLoading, setButtonLoading] = useState({ save: false });
  const direction = useBreakpointValue({
    base: "column",
    md: "row",
    lg: "row",
  });
  const { staticData, setStaticData, loading } = useContext(StaticContext);
  const [rows, setRows] = useState(
    staticData["menubar"]
      ? Object.entries(JSON.parse(staticData["menubar"][0]))
      : []
  );
  useEffect(() => {
    console.log("Inside MenuPanel.js useEffect()");
    setRows(
      staticData["menubar"]
        ? Object.entries(JSON.parse(staticData["menubar"][0]))
        : []
    );
  }, []);
  const handleInputChange = (index, value) => {
    const newRows = [...rows];
    newRows[index][0] = value;
    setRows(newRows);
  };

  const handleAddRow = () => {
    setRows([...rows, ["", []]]);
  };

  const handleDeleteRow = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
  };

  const handleCreateStaticData = async (key, value) => {
    console.log("Got::", key, value);
    await services.createStaticData(key, value);
    setStaticData({ ...staticData, [key]: value });
  };

  const formHandler = async (key, value, index) => {
    let newRows = [...rows];
    newRows[index][1] = value;
    setRows(newRows);
  };

  const handleSave = async () => {
    setButtonLoading({ save: true });
    try {
      let gridData = {};
      for (let i = 0; i < rows.length; i++) {
        if (rows[i][0] && rows[i][1] && rows[i][1].length > 0) {
          gridData[rows[i][0]] = rows[i][1];
        }
      }
      console.log(gridData);
      const response = await services.createStaticData("menubar", [
        JSON.stringify(gridData),
      ]);
      toast({
        title: "Data Saved",
        description: "Data has been successfully saved.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setStaticData({ ...staticData, menubar: [JSON.stringify(gridData)] });
    } catch (error) {
      console.error("Error saving data:", error);
      toast({
        title: "Error",
        description: "An error occurred while saving data.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setButtonLoading({ save: false });
    }
  };
  console.log(rows);
  return (
    !loading && (
      <Box p={1}>
        <VStack spacing={4} mb={4}>
          {rows.map((row, index) => (
            <Flex
              key={index}
              m={1}
              width={"100%"}
              justifyContent="space-between"
              alignItems="center"
              borderWidth={3}
              borderRadius={"lg"}
              direction={direction}
              p={4}
            >
              <Box flex={2} mx={2}>
                <Input
                  placeholder="Menu Headers"
                  value={row[0]}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                />
              </Box>

              <Box
                flex={4}
                m={2}
                px={2}
                borderLeftWidth={3}
                borderRightWidth={3}
              >
                <CommonMultiSelect
                  name="dropDowns"
                  label="Select Drop Downs"
                  currentValues={row[1]}
                  handleCreateStaticData={handleCreateStaticData}
                  formHandler={(key, value) => {
                    formHandler(key, value, index);
                  }}
                  values={staticData.dropDowns}
                  width="100%"
                />
              </Box>

              <Box mx={2}>
                <IconButton
                  onClick={() => handleDeleteRow(index)}
                  icon={<DeleteIcon />}
                  color={"red"}
                >
                  Delete
                </IconButton>
              </Box>
            </Flex>
          ))}
        </VStack>
        <Flex justifyContent={"center"}>
          <Button onClick={handleAddRow} mr={2} colorScheme="blue">
            Add Row
          </Button>
          <Button
            onClick={handleSave}
            isLoading={buttonLoading.save}
            colorScheme="green"
          >
            Save
          </Button>
        </Flex>
      </Box>
    )
  );
};

export default MenuPanel;
