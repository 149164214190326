import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { FaAngleDown } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const SingleMenu = ({ menuKey, menuList }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const handleMenuClick = (text) => {
    navigate(`/search?keywords=${text}&dropdown=${text}`);
    setOpen(false);
  };

  const handleMouseOver = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };
  return (
    <Menu isOpen={open}>
      <MenuButton
        key={menuKey}
        bg={"white"}
        margin={2}
        as={Button}
        rightIcon={<Icon as={FaAngleDown} />}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      >
        <Text my="auto" fontSize={"sm"}>
          {menuKey}
        </Text>
      </MenuButton>
      <MenuList
        zIndex={10}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      >
        {menuList.map((menuItem) => (
          <MenuItem key={menuItem} onClick={() => handleMenuClick(menuItem)}>
            <Text my={0} fontFamily={"helvetica"} fontSize={"sm"}>
              {menuItem}
            </Text>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default SingleMenu;
