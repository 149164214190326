import { Box, Image, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";

const SingleAdminProduct = ({ productData }) => {
  console.log("Produc in single-admin product", productData);
  const [product, setProduct] = useState(productData);
  useEffect(() => {
    // Update local state when productData prop changes
    setProduct(productData);
  }, [productData]);
  const truncateDescription = (description, maxLength) => {
    if (description.length > maxLength) {
      return description.substring(0, maxLength) + "...";
    }
    return description;
  };
  return (
    product && (
      <Box
        key={product.id}
        borderWidth="1px"
        borderRadius="lg"
        boxShadow="sm"
        overflow="hidden"
        height={"250px"}
      >
        <Box height={"180px"} width={"100%"} mx={"auto"}>
          <Image
            src={product?.images?.[0]?.low}
            alt={product.name}
            height="100%"
            width="100%"
            objectFit="cover"
          />
        </Box>
        <Box p="4" bgColor={"white"}>
          <Text fontSize="sm" mb="2">
            {truncateDescription(product.name, 25)}
          </Text>
        </Box>
      </Box>
    )
  );
};

export default SingleAdminProduct;
