import { useContext } from "react";
import Panel from "../components/Admin/Panel";
import Login from "../components/Authentication/Login";

import { Box } from "@chakra-ui/react";
import Footer from "../components/CustomerFrontComponents/Footer";
import Header from "../components/CustomerFrontComponents/Header";
import { AuthContext } from "../context/AuthProvider";
import { StaticProvider } from "../context/StaticProvider";
const AdminPage = () => {
  const { authState } = useContext(AuthContext);

  return (
    <StaticProvider>
      <Box width={"100%"}>
        <Header adminFlag={true} />
        {authState.isAuthenticated ? <Panel /> : <Login />}
        <Footer adminFlag={true} />
      </Box>
    </StaticProvider>
  );
};

export default AdminPage;
