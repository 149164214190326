import {
  Box,
  Button,
  Center,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { StaticContext } from "../../context/StaticProvider";
import CommonInput from "../Common/CommonInput";
import CommonMultiSelect from "../Common/CommonMultiSelect";
import MultipleImageUploader from "../Common/MultipleImageUploader";
import PricesInput from "../Common/PricesInput";
import Toggler from "../Common/Toggler";
import { default as Services, default as services } from "../Services/Services";
import SingleAdminProduct from "./SingleAdminProduct";

const EditProductModal = ({ product = {}, hasData, onSave, index }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formData, setFormData] = useState(product);
  const [singleProduct, setSingleProduct] = useState(product);
  const { staticData, setStaticData, loading } = useContext(StaticContext);
  const [buttonLoading, setButtonLoading] = useState({
    save: false,
    delete: false,
  });
  // useEffect(() => {
  //   setFormData(product);
  //   setSingleProduct(product);
  // }, [product]);
  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleMultipleSelect = (key, value) => {
    setFormData({ ...formData, [key]: value });

    console.log(key, value);
  };

  const handlePriceChange = (json) => {
    setFormData({ ...formData, prices: json });
  };

  const handleImageChange = (data) => {
    setFormData({ ...formData, images: data });
  };

  const handleToggleChange = (value) => {
    setFormData({ ...formData, enabled: value });
  };
  const handleCreateStaticData = async (key, value) => {
    console.log("Got::", key, value);
    await Services.createStaticData(key, value);
    setStaticData({ ...staticData, [key]: value });
  };
  const toast = useToast();

  const handleSubmit = async (e) => {
    setButtonLoading({ ...buttonLoading, save: true });
    console.log(formData);
    try {
      const response = await services.addUpdateProduct(formData);
      console.log(response);
      onSave(index, response);
      setFormData(response);
      setSingleProduct(response);
      toast({
        title: "Data Saved",
        description: "Data has been successfully saved.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onClose();
      if (!hasData) {
        setFormData({});
      }
    } catch (err) {
      console.error("Error saving product:", err);
      toast({
        title: "Error",
        description: err.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setButtonLoading({ ...buttonLoading, save: false });
    }
  };

  const handleDelete = async () => {
    setButtonLoading({ ...buttonLoading, delete: true });
    if (window.confirm("Are you sure you want to delete this product?")) {
      try {
        const response = await services.deleteProduct(product.productId);
        console.log(response);
        onSave(index, null);
        onClose();
      } catch (err) {
        console.error("Error deleting product:", err);
      } finally {
        setButtonLoading({ ...buttonLoading, delete: false });
      }
    }
  };
  return (
    <>
      {!hasData && (
        <Flex justifyContent={"center"}>
          <Button onClick={onOpen} colorScheme="blue">
            Add New Product
          </Button>
        </Flex>
      )}
      {hasData && (
        <Box onClick={onOpen}>
          {singleProduct && <SingleAdminProduct productData={singleProduct} />}
        </Box>
      )}
      {!loading && formData && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent maxW={{ base: "90%", md: "600px", lg: "800px" }}>
            <ModalHeader>
              {hasData ? "Edit Product" : "Add New Product"}
            </ModalHeader>
            <ModalCloseButton />
            <Box
              w="100%"
              p="8"
              mx="auto"
              my="2"
              borderWidth="1px"
              borderRadius="lg"
            >
              <Flex
                justifyContent={"space-between"}
                verticalAlign={"center"}
                mb={3}
              >
                <Box verticalAlign={"center"}>
                  <Toggler
                    onChange={handleToggleChange}
                    value={formData.enabled}
                  />
                </Box>
                <Box>
                  <Button
                    onClick={handleDelete}
                    colorScheme="red"
                    isLoading={buttonLoading.delete}
                  >
                    Delete
                  </Button>
                </Box>
              </Flex>
              <CommonInput
                onChangeHandler={handleFormChange}
                name={"name"}
                value={formData.name}
                label="Name"
              />

              <CommonInput
                onChangeHandler={handleFormChange}
                name={"description"}
                value={formData.description}
                label="Description"
                type="textarea"
              />

              <CommonInput
                onChangeHandler={handleFormChange}
                name={"minPreOrderDays"}
                value={formData.minPreOrderDays}
                label="Minimum Pre Order Days"
                type="number"
                defaultValue={2}
              />

              <CommonMultiSelect
                name="ingredients"
                formHandler={handleMultipleSelect}
                label="Ingredients"
                currentValues={product.ingredients}
                values={staticData.ingredients}
                handleCreateStaticData={handleCreateStaticData}
              />

              <CommonMultiSelect
                name="dropDowns"
                formHandler={handleMultipleSelect}
                label="Drop Downs"
                currentValues={product.dropDowns}
                values={staticData.dropDowns}
                handleCreateStaticData={handleCreateStaticData}
              />

              <CommonMultiSelect
                name="sectionHeaders"
                formHandler={handleMultipleSelect}
                label="Section Headers"
                currentValues={product.sectionHeaders}
                values={staticData.sectionHeaders}
                handleCreateStaticData={handleCreateStaticData}
              />

              <CommonMultiSelect
                name="tags"
                formHandler={handleMultipleSelect}
                label="Tags"
                currentValues={product.tags}
                values={staticData.tags}
                handleCreateStaticData={handleCreateStaticData}
              />

              <CommonInput
                onChangeHandler={handleFormChange}
                name={"type"}
                value={formData.type}
                label="Product Type"
                type="select"
                options={staticData.type}
              />

              <PricesInput
                label="Prices"
                onChangeHandler={handlePriceChange}
                dropDownValues={staticData.quantities.map((q) =>
                  q.split("$#")[0]?.trim()
                )}
                pricesObj={formData.prices}
                name="prices"
              />

              <MultipleImageUploader
                onChange={handleImageChange}
                imageList={product.images}
              />

              <Flex justify={"space-between"} mt="2">
                <Button
                  colorScheme="green"
                  type="submit"
                  onClick={handleSubmit}
                  isLoading={buttonLoading.save}
                >
                  Save
                </Button>
                <Button colorScheme="blue" onClick={onClose}>
                  Cancel
                </Button>
              </Flex>
            </Box>
          </ModalContent>
        </Modal>
      )}
      {loading && (
        <Center height="60vh">
          <Spinner size="xl" />
        </Center>
      )}
    </>
  );
};

export default EditProductModal;
