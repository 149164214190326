import {
  Box,
  Button,
  Flex,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import services from "../Services/Services";

const OrderTable = ({ data, setData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [selectedOrder, setSelectedOrder] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const sortedData = useMemo(() => {
    let sortableData = [...data];
    if (sortConfig.key) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  const paginatedData = sortedData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const convertToIndianDateTime = (dateStr) =>
    new Date(dateStr)
      .toLocaleString("en-IN", {
        day: "2-digit",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
      .replace(",", "");

  const handleRowClick = async (order) => {
    setSelectedOrder(order);
    onOpen();

    try {
      if (!order.seenFlag) {
        const res = await services.updateSeenOrder(order.orderId);
        console.log(res);
        setData((prevData) =>
          prevData.map((row) =>
            order.orderId === row.orderId ? { ...row, seenFlag: true } : row
          )
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box>
      <TableContainer>
        <Table variant="simple">
          <TableCaption>Order Details</TableCaption>
          <Thead>
            <Tr>
              <Th onClick={() => requestSort("orderId")}>Order Id</Th>
              <Th onClick={() => requestSort("createdAt")}>Order Placed On</Th>
              <Th onClick={() => requestSort("deliveryDate")}>Delivery Date</Th>
              <Th>Product Name + Flavor</Th>
              <Th>WhatsApp</Th>
            </Tr>
          </Thead>
          <Tbody>
            {paginatedData.map((row) => (
              <Tr
                key={row.orderId}
                onClick={() => handleRowClick(row)}
                cursor="pointer"
                bg={row.seenFlag ? "white" : "green.100"}
              >
                <Td>{row.orderId}</Td>
                <Td>{convertToIndianDateTime(row.createdAt)}</Td>
                <Td>{convertToIndianDateTime(row.deliveryDate)}</Td>
                <Td>
                  {row.productName} ({row.flavor})
                </Td>
                <Td>
                  <IconButton
                    as="a"
                    href={`https://wa.me/${row.customerPhoneNumber}`}
                    icon={<FaWhatsapp />}
                    aria-label="WhatsApp"
                    colorScheme="green"
                    target="_blank"
                    onClick={(e) => e.stopPropagation()} // Prevent row click
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex justifyContent="space-between" alignItems="center" mt="4">
        <Button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        <Select
          width="fit-content"
          value={rowsPerPage}
          onChange={(e) => setRowsPerPage(Number(e.target.value))}
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={15}>15</option>
        </Select>
        <Button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage * rowsPerPage >= data.length}
        >
          Next
        </Button>
      </Flex>

      {selectedOrder && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Order Details</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>
                <strong>Order Id:</strong> {selectedOrder.orderId}
              </Text>
              <Text>
                <strong>Order Placed On:</strong>{" "}
                {convertToIndianDateTime(selectedOrder.createdAt)}
              </Text>
              <Text>
                <strong>Delivery Date:</strong>{" "}
                {convertToIndianDateTime(selectedOrder.deliveryDate)}
              </Text>
              <Text>
                <strong>Product Name:</strong>{" "}
                <Link
                  href={`/search?productId=${selectedOrder.productId}`}
                  color="teal.500"
                >
                  {selectedOrder.productName}
                </Link>
              </Text>
              <Text>
                <strong>Flavor:</strong> {selectedOrder.flavor}
              </Text>
              <Text>
                <strong>Quantity:</strong> {selectedOrder.quantity}
              </Text>
              <Text>
                <strong>Customer's Phone Number:</strong>{" "}
                {selectedOrder.customerPhoneNumber}
              </Text>
              <Text>
                <strong>Customer's Name:</strong> {selectedOrder.customerName}
              </Text>
              <Text>
                <strong>Estimated Price:</strong> {selectedOrder.estimatedPrice}
              </Text>
            </ModalBody>
            <ModalFooter>
              <IconButton
                as="a"
                href={`https://wa.me/${selectedOrder.customerPhoneNumber}`}
                icon={<FaWhatsapp />}
                aria-label="WhatsApp"
                colorScheme="green"
                target="_blank"
              />
              <Button colorScheme="blue" ml={3} onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};

export default OrderTable;
