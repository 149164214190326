import { Text } from "@chakra-ui/react";
import { useState } from "react";

const TruncatedDescription = ({ description, maxLength }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const shouldTruncate = description.length > maxLength;

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Text fontSize="sm" color="gray.600" mb={2} wordBreak="break-word">
      {isExpanded
        ? description
        : `${description.substring(0, maxLength)}${
            shouldTruncate ? "..." : ""
          }`}
      {shouldTruncate && (
        <Text
          as="span"
          color="gray.500"
          cursor="pointer"
          onClick={toggleExpansion}
        >
          {isExpanded ? " Show less" : " Read more"}
        </Text>
      )}
    </Text>
  );
};

export default TruncatedDescription;
