import { Box, Flex, Slide, useBreakpointValue } from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useLocation } from "react-router-dom";
import { StaticContext } from "../../context/StaticProvider";
import services from "../Services/Services";
import ButtonPanel from "./ProductSubComponents/ButtonPanel";
import ImageGallery from "./ProductSubComponents/ImageGallery";
import OrderDeliveryDetails from "./ProductSubComponents/OrderDeliveryDetails";
import OrderDetailsPanel from "./ProductSubComponents/OrderDetailsPanel";
import OrderPlacedModal from "./ProductSubComponents/OrderPlacedModal";
import ProductDetails from "./ProductSubComponents/ProductDetails";
import UserDetailsForm from "./ProductSubComponents/UserDetailForm";
const ProductComponent = () => {
  const location = useLocation();

  const { staticData, loading } = useContext(StaticContext);
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const productData = location.state;
  const [product, setProduct] = useState(productData);
  const [showOrderDiv, setShowOrderDiv] = useState(false);
  const calculateTotal = function (weight, qty, flavor) {
    try {
      const [wtKey, wt] = weight.split("$#");

      let total = parseInt(wt);
      const flavorPrice = flavor.split("$#")[1];

      if (flavorPrice) {
        const qty = staticData.quantities;
        const qtyMap = {};
        for (let item of qty) {
          qtyMap[item.split("$#")[0]?.trim()] = item.split("$#")[1]?.trim();
        }
        console.log(qtyMap);
        total += qtyMap[wtKey.trim()] * flavorPrice;
      }

      total *= parseInt(qty);
      if (isNaN(total)) {
        return "Total Price will be informed on WhatsApp / Call";
      }
      return "₹ " + total;
    } catch (error) {
      console.log(
        "Some error occurred while calculating price. inputs are",
        weight,
        qty,
        flavor
      );
    }
    return "Total Price will be informed on WhatsApp / Call";
  };
  const [formData, setFormData] = useState({
    weight:
      Object.keys(product.prices)[0] +
      "$#" +
      product.prices[Object.keys(product.prices)[0]],
    quantity: 1,
    flavor: "",
    deliveryDt: tomorrow,
    comments: "",
    address: "",
    deliveryRequired: false,
    customerName: "",
    customerMobile: "",
    pinCode: "",
  });
  const [estimated, setEstimated] = useState("");

  const handleChanges = (key, value) => {
    if (key === "quantity" && (value < 1 || Number.isNaN(value))) {
      value = "";
    }
    const updatedForm = { ...formData, [key]: value };
    setFormData(updatedForm);
    setEstimated(
      calculateTotal(
        updatedForm.weight,
        updatedForm.quantity,
        updatedForm.flavor
      )
    );
  };
  const [number, setNumber] = useState(null);
  useEffect(() => {
    console.log("Inside ProductComponent.useEffect() called");
    setProduct(productData);
  });

  useEffect(() => {
    setFormData((prev) => {
      const blankFormData = {
        weight:
          Object.keys(product.prices)[0] +
          "$#" +
          product.prices[Object.keys(product.prices)[0]],
        quantity: 1,
        flavor: "",
        deliveryDt: tomorrow,
        comments: "",
        address: "",
        deliveryRequired: false,
        customerName: "",
        customerMobile: "",
        pinCode: "",
      };
      setEstimated(
        calculateTotal(
          blankFormData.weight,
          blankFormData.quantity,
          blankFormData.flavor
        )
      );
      return blankFormData;
    });
  }, [product]);

  useEffect(() => {
    console.log("Inside ProductComponent.useEffect() - number called");
    console.log(staticData);
    if (staticData && staticData.footer) {
      const footer = JSON.parse(staticData.footer);
      setNumber(footer.whatsapp);
    }
    if (staticData?.flavors?.[0]) {
      setFormData({ ...formData, flavor: staticData?.flavors?.[0] });
    }
    setTimeout(() => {
      setShowOrderDiv(true);
    }, 1000);
  }, [loading]);

  const direction = useBreakpointValue({ base: "column", md: "row" });
  const styles = useBreakpointValue({
    base: { mx: 4, my: 2, px: 4, py: 2, mt: 6, p: 0 },
    md: { mx: 8, my: 4, px: 8, py: 8, mt: 0, p: 4 },
  });
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [isModalOpen, setIsModalOpen] = useState({
    A: false,
    B: false,
    C: false,
  });

  const setModalOpen = (modalName, state) => {
    setIsModalOpen((prev) => ({ ...prev, [modalName]: state }));
  };
  const [showOrderPlaced, setShowOrderPlaced] = useState(false);
  const handleOrderNow = async () => {
    console.log(formData);
    let message = `Hi,
      I would like to place an order for the following product:

      Product Type: ${product.type}
      Name: ${product.name}
      Flavour: ${formData.flavor.split("$#")[0]}
      Weight: ${formData.weight.split("$#")[0]}
      Quantity: ${formData.quantity}
      Expected Delivery Date: ${formData.deliveryDt.toString().substring(0, 24)}
      Delivery Address: ${formData.address}
      Special Instructions: ${formData.comments}
      Estimated Price: ${estimated}

      You can view the product details here: ${
        window.location.origin
      }/search?productId=${product.productId}

      Thank you!`;
    console.log(message.split("\n"));
    let msgList = message.split("\n");
    msgList = msgList.map((msg) => msg.trim());
    message = msgList.join("%0A");
    console.log(message);
    console.log(number);
    //window.open(`https://wa.me/${number}?text=${message}`, "_blank"); // Settings
    await saveOrder();
    setModalOpen("A", false);
    setModalOpen("B", false);
    setModalOpen("C", false);
    setShowOrderPlaced(true);
  };

  const saveOrder = async () => {
    try {
      const data = {
        orderId: null,
        orderType: product.type,
        deliveryDate: formData.deliveryDt,
        productId: product.productId,
        productName: product.name,
        flavor: formData.flavor,
        quantity: formData.quantity,
        weight: formData.weight.split("$#")[0],
        homeDelivery: formData.deliveryRequired,
        deliveryAddress: formData.address,
        comments: formData.comments,
        customerPhoneNumber: formData.customerMobile,
        customerName: formData.customerName,
        estimatedPrice: estimated,
      };

      const res = await services.saveOrder(data);
      console.log(res);
    } catch (error) {
      console.log("Error saving order", error);
    }
  };
  const maxHeight = useBreakpointValue({
    base: "auto",
    md: "70vh",
  });
  const subComponentRef = useRef(null); // Create ref
  const [isSubComponentInView, setIsSubComponentInView] = useState(true);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsSubComponentInView(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (subComponentRef.current) {
      observer.observe(subComponentRef.current);
    }

    return () => {
      if (subComponentRef.current) {
        observer.unobserve(subComponentRef.current);
      }
    };
  }, []);

  return (
    product && (
      <Box
        px={styles.px}
        py={styles.py}
        my={styles.my}
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        className="mainBox"
      >
        <Flex
          direction={direction}
          justifyContent="space-around"
          overflow="hidden"
        >
          {/* Scrollable Image Gallery Section */}
          <Box flex="3" maxHeight={maxHeight} p={styles.p}>
            <ImageGallery product={product} />
          </Box>

          {/* Scrollable Product Details Section */}
          <Box
            flex="4"
            overflowY="scroll"
            maxHeight={maxHeight}
            p={styles.p}
            css={{
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-track": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#aaa",
                borderRadius: "24px",
              },
            }}
          >
            <ProductDetails
              product={product}
              staticData={staticData}
              styles={styles}
              loading={loading}
              setModalOpen={setModalOpen}
              showOrderDiv={showOrderDiv}
              subComponentRef={subComponentRef}
            />
          </Box>
        </Flex>

        <Slide
          direction="bottom"
          in={showOrderDiv && (isMobile || !isSubComponentInView)}
          style={{ zIndex: 9 }}
        >
          <Box
            boxShadow="lg"
            bg={"white"}
            borderTopLeftRadius={"lg"}
            borderTopRightRadius={"lg"}
            border="1px solid #ddd"
            p={4}
          >
            <ButtonPanel
              loading={loading}
              showOrderDiv={showOrderDiv}
              setModalOpen={setModalOpen}
            />
          </Box>
        </Slide>
        <Slide direction="bottom" in={isModalOpen.A} style={{ zIndex: 11 }}>
          <OrderDetailsPanel
            estimated={estimated}
            formData={formData}
            handleChanges={handleChanges}
            handleOrderNow={handleOrderNow}
            isMobile={isMobile}
            product={product}
            setModalOpen={setModalOpen}
            staticData={staticData}
            tomorrow={tomorrow}
          />
        </Slide>
        <Slide direction="bottom" in={isModalOpen.B} style={{ zIndex: 11 }}>
          <OrderDeliveryDetails
            estimated={estimated}
            formData={formData}
            handleChanges={handleChanges}
            isMobile={isMobile}
            setModalOpen={setModalOpen}
          />
        </Slide>
        <Slide direction="bottom" in={isModalOpen.C} style={{ zIndex: 11 }}>
          <UserDetailsForm
            formData={formData}
            handleChanges={handleChanges}
            handleOrderNow={handleOrderNow}
            isMobile={isMobile}
            setModalOpen={setModalOpen}
            estimated={estimated}
          />
        </Slide>
        <OrderPlacedModal
          isOpen={showOrderPlaced}
          onClose={() => setShowOrderPlaced(false)}
        />
      </Box>
    )
  );
};

export default ProductComponent;
