import {
  Button,
  Flex,
  Icon,
  IconButton,
  Input,
  Text,
  Tooltip,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { FaSearch, FaTimes } from "react-icons/fa";
import { FaMapLocationDot } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthProvider";
import { StaticContext } from "../../context/StaticProvider";
import Logo from "../Common/Logo";

const Header = ({ adminFlag }) => {
  const displayText = useBreakpointValue({ base: "none", md: "inline" });
  const fontSize = 22;
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { logout, authState } = useContext(AuthContext);

  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const { staticData, loading } = useContext(StaticContext);
  const [headerData, setHeaderData] = useState(
    (staticData && staticData.footer && JSON.parse(staticData.footer[0])) || {}
  );

  useEffect(() => {
    console.log("Footer, useEffect");
    setHeaderData(
      (staticData && staticData.footer && JSON.parse(staticData.footer[0])) ||
        {}
    );
  }, [loading]);

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearch = () => {
    if (searchQuery && searchQuery.trim()) {
      navigate(`/search?keywords=${searchQuery.trim()}`);
      setShowSearch(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearchIconClick = () => {
    setShowSearch(true);
  };

  const handleReset = () => {
    setSearchQuery("");
    setShowSearch(false);
  };

  const handleMapClick = () => {
    window.open(!loading && headerData.location, "_blank");
  };

  return (
    <Flex
      className="mainBox"
      as="header"
      padding="1rem"
      boxShadow="md"
      bg="gray.70"
      height={"100px"}
      bgColor={"white"}
    >
      {!showSearch && (
        <>
          {/* Logo */}
          <Logo />

          {/* Search Bar */}

          {adminFlag && authState.isAuthenticated && (
            <Flex align="center" ml="auto">
              <Button onClick={logout} colorScheme="red">
                Logout
              </Button>
            </Flex>
          )}

          {!adminFlag && (
            <Flex align="center" ml="auto">
              {isMobile ? (
                <Flex>
                  <Tooltip label="Locate Us">
                    <IconButton
                      icon={<FaMapLocationDot />}
                      fontSize={fontSize}
                      onClick={handleMapClick}
                      aria-label="Search"
                      bg="transparent"
                      className="shake"
                      m={0}
                    />
                  </Tooltip>

                  <IconButton
                    icon={<FaSearch />}
                    fontSize={fontSize}
                    onClick={handleSearchIconClick}
                    aria-label="Search"
                    bg="transparent"
                  />
                </Flex>
              ) : (
                <Flex align="center">
                  <Tooltip label="Locate Us" hasArrow>
                    <Button
                      bg="gray.150"
                      variant={"outline"}
                      colorScheme="blue"
                      leftIcon={<Icon as={FaMapLocationDot} />}
                      size="md"
                      onClick={handleMapClick}
                      mr={4}
                    >
                      <Text
                        display={displayText}
                        colorScheme="blue"
                        my={"auto"}
                      >
                        Locate Us
                      </Text>
                    </Button>
                  </Tooltip>
                  <Input
                    variant="flushed"
                    placeholder="Search..."
                    bg="white"
                    borderColor="gray.200"
                    onChange={handleInputChange}
                    onKeyDown={handleKeyPress}
                    value={searchQuery}
                    width="auto"
                  />
                  <Button
                    bg="gray.150"
                    leftIcon={<Icon as={FaSearch} />}
                    size="md"
                    onClick={handleSearch}
                  >
                    <Text display={displayText} color={"black"} my={"auto"}>
                      Search
                    </Text>
                  </Button>
                </Flex>
              )}
            </Flex>
          )}
        </>
      )}

      {isMobile && showSearch && (
        <Flex align="center" width="100%">
          <Input
            variant="flushed"
            placeholder="Search..."
            bg="white"
            borderColor="gray.200"
            onChange={handleInputChange}
            onKeyDown={handleKeyPress}
            value={searchQuery}
            autoFocus
            width="100%"
          />
          <IconButton
            icon={<FaTimes />}
            onClick={handleReset}
            aria-label="Reset"
            ml="2"
            bg="transparent"
          />
        </Flex>
      )}
    </Flex>
  );
};

export default Header;
