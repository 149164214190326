import {
  Button,
  Flex,
  Input,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";

import { StaticContext } from "../../context/StaticProvider";
import services from "../Services/Services";

const LOVPanel = () => {
  const toast = useToast();
  const { staticData, setStaticData, loading } = useContext(StaticContext);
  const [data, setData] = useState(staticData && Object.entries(staticData));

  useEffect(() => {
    console.log("Inside LOVPanel useEffect");
    setData(staticData && Object.entries(staticData));
  }, [staticData]);

  const handleSave = async (key, value, index) => {
    console.log(key, value, index);
    try {
      await services.createStaticData(key, value);
      await setStaticData({
        ...staticData,
        [key]: value,
      });

      toast({
        title: "Data Saved",
        description: "Data has been successfully saved.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error saving data:", error);
      toast({
        title: "Error",
        description: "An error occurred while saving data.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDelete = async (key, index) => {
    console.log(key);
    try {
      // eslint-disable-next-line no-restricted-globals
      const confirmation = confirm(`Are you sure you want to delete ${key}`);
      if (!confirmation) {
        return;
      }
      // eslint-disable-next-line no-restricted-globals
      const reconfirmation = confirm(
        `Are you really sure you want to delete ${key}`
      );
      if (!reconfirmation) {
        return;
      }
      await services.deleteStaticData(key);
      setStaticData((prev) => {
        const newStaticData = { ...prev };
        delete newStaticData[key];
        return newStaticData;
      });

      toast({
        title: "Data Deleted",
        description: "Data has been successfully Deleted.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error saving data:", error);
      toast({
        title: "Error",
        description: "An error occurred while deleting data.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const handleTextAreaChange = (e, index) => {
    try {
      const newData = [...data];
      newData[index][1] = JSON.parse(e.target.value);
      setData(newData);
    } catch (error) {
      alert("Please do not change this section!");
    }
  };
  return (
    !loading &&
    staticData && (
      <Flex direction={"column"}>
        <Text color={"red"} textAlign={"center"} fontWeight={"bold"}>
          Warning: This section allows for the permanent deletion of data. Once
          deleted, the data cannot be recovered. If you are unsure about this
          action, please proceed with caution or consult with an experienced
          user.
        </Text>

        <Button
          colorScheme="blue"
          m={4}
          width={"10vw"}
          alignSelf={"center"}
          onClick={() => {
            setData([["", []], ...data]);
          }}
        >
          Add More
        </Button>
        {data.map((row, index) => (
          <Flex
            p={4}
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            key={index}
            m={1}
          >
            <Input
              type="text"
              value={row[0]}
              width={"50%"}
              onChange={(e) => {
                const newData = [...data];
                newData[index][0] = e.target.value;
                setData(newData);
              }}
            />
            <Textarea
              name={row[0]}
              value={JSON.stringify(row[1])}
              onChange={(e) => handleTextAreaChange(e, index)}
              rows={10}
              width={"50%"}
              m={1}
            />
            <Flex justifyContent={"center"}>
              <Button
                colorScheme="green"
                onClick={(e) => {
                  handleSave(data[index][0], data[index][1], index);
                }}
                m={4}
              >
                Save
              </Button>
              <Button
                colorScheme="red"
                onClick={(e) => {
                  handleDelete(data[index][0], index);
                }}
                m={4}
              >
                Delete
              </Button>
            </Flex>
          </Flex>
        ))}
      </Flex>
    )
  );
};

export default LOVPanel;
