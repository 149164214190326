import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { StaticContext } from "../../context/StaticProvider";
import services from "../Services/Services";

const SiteSettingPanel = () => {
  const toast = useToast();
  const [buttonLoading, setButtonLoading] = useState({ save: false });
  const { staticData, setStaticData, loading } = useContext(StaticContext);
  const [form, setForm] = useState({
    email1: "",
    email2: "",
    phone1: "",
    phone2: "",
    instagram: "",
    facebook: "",
    footerText: "",
    whatsapp: "",
    location: "",
  });
  useEffect(() => {
    console.log("Inside Sitesetting.js useEffect()");
    if (staticData && staticData.footer && staticData.footer[0]) {
      setForm({ ...form, ...JSON.parse(staticData.footer[0]) });
    }
  }, [staticData]);
  const handleInputChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    setButtonLoading({ save: true });
    try {
      const response = await services.createStaticData("footer", [
        JSON.stringify(form),
      ]);
      toast({
        title: "Data Saved",
        description: "Data has been successfully saved.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setStaticData({ ...staticData, footer: [JSON.stringify(form)] });
    } catch (error) {
      console.error("Error saving data:", error);
      toast({
        title: "Error",
        description: "An error occurred while saving data.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setButtonLoading({ save: false });
    }
  };

  return (
    !loading &&
    form && (
      <Box p={"auto"} width={"60%"} margin={"auto"}>
        <Flex justifyContent={"center"}>
          <Button
            onClick={handleSave}
            colorScheme="green"
            isLoading={buttonLoading.save}
          >
            Save
          </Button>
        </Flex>
        <VStack spacing={4} mb={4}>
          <FormControl>
            <FormLabel>Phone 1</FormLabel>
            <Input
              placeholder="Phone 1"
              type="text"
              name="phone1"
              value={form.phone1}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Phone 2</FormLabel>
            <Input
              placeholder="Phone 2"
              type="text"
              name="phone2"
              value={form.phone2}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Email 1</FormLabel>
            <Input
              placeholder="Email 1"
              type="text"
              name="email1"
              value={form.email1}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Email 2</FormLabel>
            <Input
              placeholder="Email 2"
              type="text"
              name="email2"
              value={form.email2}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Instagram</FormLabel>
            <Input
              placeholder="Enter https:// link"
              type="text"
              name="instagram"
              value={form.instagram}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Facebook</FormLabel>
            <Input
              placeholder="Enter https:// link"
              type="text"
              name="facebook"
              value={form.facebook}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Add Footer Text Here</FormLabel>
            <Textarea
              placeholder="Add Text Here"
              type="text"
              name="footerText"
              value={form.footerText}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Whatsapp Number</FormLabel>
            <Input
              placeholder="Add Whatsapp Number"
              type="text"
              name="whatsapp"
              value={form.whatsapp}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Location URL</FormLabel>
            <Input
              placeholder="Add Location URL with https://"
              type="text"
              name="location"
              value={form.location}
              onChange={handleInputChange}
            />
          </FormControl>
        </VStack>
        <Flex justifyContent={"center"}>
          <Button
            onClick={handleSave}
            colorScheme="green"
            isLoading={buttonLoading.save}
          >
            Save
          </Button>
        </Flex>
      </Box>
    )
  );
};

export default SiteSettingPanel;
