import { Box } from "@chakra-ui/react";
import React from "react";

import { CUIAutoComplete } from "chakra-ui-autocomplete";

const CommonMultiSelect = ({
  name = "",
  label = "",
  values = [],
  formHandler,
  currentValues = [],
  handleCreateStaticData,
  width = "auto",
}) => {
  const [pickerItems, setPickerItems] = React.useState(
    values.map((v) => ({ value: v, label: v }))
  );
  const [selectedItems, setSelectedItems] = React.useState(
    currentValues.map((v) => ({ value: v, label: v }))
  );

  const handleCreateItem = async (item) => {
    setPickerItems((curr) => [...curr, item]);
    setSelectedItems((curr) => [...curr, item]);
    formHandler(name, [...selectedItems.map((v) => v.value), item.value]);
    await handleCreateStaticData(name, [
      ...pickerItems.map((v) => v.value),
      item.value,
    ]);
  };

  const handleSelectedItemsChange = (selectedItems) => {
    console.log("selected Itemps::", selectedItems);
    if (selectedItems) {
      setSelectedItems(selectedItems);
      formHandler(
        name,
        selectedItems.map((v) => v.value)
      );
    }
  };

  return (
    <Box my="0" py="0" position="relative" width={width}>
      <CUIAutoComplete
        label={label}
        placeholder="Type something..."
        onCreateItem={handleCreateItem}
        items={pickerItems}
        tagStyleProps={{
          rounded: "full",
          pt: 1,
          pb: 2,
          px: 2,
          fontSize: "1rem",
        }}
        selectedItems={selectedItems}
        onSelectedItemsChange={(changes) =>
          handleSelectedItemsChange(changes.selectedItems)
        }
        listStyleProps={{
          position: "absolute",
          zIndex: 10,
          width: "100%",
          background: "white",
          boxShadow: "md",
        }}
      />
    </Box>
  );
};

export default CommonMultiSelect;
