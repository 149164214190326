import {
  Box,
  Center,
  Grid,
  Spinner,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import services from "../Services/Services";
import EditProductModal from "./EditProductModal";

const AllProducts = ({ type }) => {
  const columns = useBreakpointValue({ base: 2, md: 5, lg: 8 });
  const [products, setProducts] = useState([]);
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleProductUpdate = (index, product) => {
    console.log(product, index);
    console.log("Updating ALL PRODUCT list...");
    const updatedProducts = [...products];
    if (index === -1) {
      updatedProducts.unshift(product);
    } else if (product === null) {
      updatedProducts.splice(index, 1);
    } else {
      updatedProducts[index] = product;
    }
    setProducts(updatedProducts);
  };
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const data = await services.fetchProducts(type);

        setProducts(data);

        let headerList = ["All Products"];

        setSections(headerList);
      } catch (error) {
        console.error("Error loading JSON file:", error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
    setProducts(products);
  }, []);

  const styles = useBreakpointValue({
    base: { mx: 4, my: 2, px: 4, py: 2 },
    md: { mx: 8, my: 4, px: 8, py: 8 },
  });

  return !loading ? (
    <Box>
      <EditProductModal
        hasData={false}
        onSave={handleProductUpdate}
        key={-1}
        index={-1}
      />

      {sections ? (
        sections.map((section, index) => {
          return (
            <Box
              key={index}
              px={styles.px}
              py={styles.py}
              mx={styles.mx}
              my={styles.my}
              borderWidth="1px"
              borderRadius="md"
              boxShadow="md"
              className="mainBox"
            >
              <Text fontSize="2xl">{section}</Text>
              <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={6}>
                {products &&
                  products.map((product, index) => (
                    <EditProductModal
                      key={product.productId}
                      product={product}
                      hasData={true}
                      onSave={handleProductUpdate}
                      index={index}
                    />
                  ))}
              </Grid>
            </Box>
          );
        })
      ) : (
        <Text fontSize="2xl">Nothing Found</Text>
      )}
    </Box>
  ) : (
    <Center height="60vh">
      <Spinner size="xl" />
    </Center>
  );
};

export default AllProducts;
