import { CloseIcon } from "@chakra-ui/icons";
import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Text,
  Textarea,
} from "@chakra-ui/react";

const OrderDeliveryDetails = ({
  isMobile,
  estimated,
  handleChanges,
  formData,
  setModalOpen,
}) => {
  return (
    <Flex
      direction="column"
      p={4}
      bg="white"
      width="100%"
      alignItems="center"
      height={"70vh"}
      overflowY="auto"
      borderTopRadius={"lg"}
      border={"1px solid #ddd"}
      boxShadow={"2xl"}
    >
      <Flex width={isMobile ? "100%" : "50%"} direction="column">
        <Flex justifyContent={"right"} cursor={"pointer"}>
          <CloseIcon
            onClick={() => {
              setModalOpen("A", false);
              setModalOpen("B", false);
            }}
          />
        </Flex>
        <Text fontSize="md" fontWeight="bold" mb={4}>
          Estimated Price: {estimated}
          <span style={{ color: "red" }}>*</span>
        </Text>

        <FormControl mb={4}>
          <FormLabel fontSize="md" fontWeight="bold">
            Do you need Home Delivery?
          </FormLabel>
          <Checkbox
            isChecked={formData.deliveryRequired}
            onChange={(e) =>
              handleChanges("deliveryRequired", e.target.checked)
            }
          >
            Yes
          </Checkbox>
        </FormControl>

        {formData.deliveryRequired && (
          <>
            <FormControl mb={4}>
              <FormLabel fontSize="md" fontWeight="bold">
                Delivery Note
              </FormLabel>
              <Text mb={2} fontWeight="bold">
                <span style={{ color: "red" }}>*</span> Note that extra delivery
                charges are applicable based on your location.
              </Text>
              <FormLabel fontSize="md" fontWeight="bold">
                Delivery Address
              </FormLabel>
              <Textarea
                placeholder="Please mention full address with pincode"
                value={formData.address}
                onChange={(e) => handleChanges("address", e.target.value)}
              />
            </FormControl>
          </>
        )}

        <FormControl mb={4}>
          <FormLabel fontSize="md" fontWeight="bold">
            Special Instructions (Optional)
          </FormLabel>
          <Textarea
            placeholder="Enter any special instructions"
            value={formData.comments}
            onChange={(e) => handleChanges("comments", e.target.value)}
          />
        </FormControl>

        <Flex justify="left" align="center" mb={4}>
          <Text fontSize="sm">
            <span style={{ color: "red" }}>*</span> Price varies based on
            customizations and selected flavours.
          </Text>
        </Flex>
        <Flex justifyContent="space-between">
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => {
              setModalOpen("B", false);
              setModalOpen("C", true);
            }}
          >
            Next
          </Button>
          <Button
            variant="ghost"
            onClick={() => {
              setModalOpen("A", true);
              setModalOpen("B", false);
            }}
          >
            Back
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default OrderDeliveryDetails;
