import { DeleteIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Select,
} from "@chakra-ui/react";
import React, { useState } from "react";

const PricesInput = ({
  onChangeHandler,
  dropDownValues = [],
  pricesObj = {},
  label = "",
  name = "",
}) => {
  const [prices, setPrices] = useState(Object.entries(pricesObj));
  const changeHandler = (newPrices) => {
    const json = {};
    for (let i = 0; i < newPrices.length; i++) {
      if (newPrices[i][1]) json[newPrices[i][0]] = newPrices[i][1];
    }
    onChangeHandler(json);
  };
  const handleDDChange = (idx, value, subIndex) => {
    let newPrices = [...prices];
    newPrices[idx][subIndex] = value;
    setPrices(newPrices);
    changeHandler(newPrices);
  };
  const updateRows = (action, idx) => {
    let newPrices = [...prices];
    if (action === "add") {
      newPrices.push([dropDownValues[0], ""]);
      setPrices(newPrices);
    } else if (action === "delete") {
      newPrices.splice(idx, 1);
      setPrices(newPrices);
    }
    changeHandler(newPrices);
  };

  return (
    <FormControl isRequired>
      <FormLabel>{label}</FormLabel>
      {prices.map((price, index) => (
        <Flex key={index}>
          <Select
            value={price[0]}
            onChange={(e) => {
              handleDDChange(index, e.target.value, 0);
            }}
          >
            {dropDownValues.map((v, index) => (
              <option key={index} value={v}>
                {v}
              </option>
            ))}
          </Select>
          <Input
            type="text"
            value={price[1]}
            placeholder="Enter price"
            onChange={(e) => {
              handleDDChange(index, e.target.value, 1);
            }}
          />
          <IconButton
            onClick={() => updateRows("delete", index)}
            icon={<DeleteIcon />}
            color={"red"}
          ></IconButton>
        </Flex>
      ))}
      <Button
        onClick={() => {
          updateRows("add");
        }}
      >
        Add Row
      </Button>
    </FormControl>
  );
};

export default PricesInput;
