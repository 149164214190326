import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { StaticContext } from "../../../context/StaticProvider";

const UserDetailsForm = ({
  isMobile,
  handleChanges,
  formData,
  handleOrderNow,
  setModalOpen,
  estimated,
}) => {
  const { staticData, loading } = useContext(StaticContext);
  const [errors, setErrors] = useState({
    customerName: "",
    customerMobile: "",
  });

  const validatePhone = (phone) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

  const handleValidation = () => {
    let isValid = true;
    const newErrors = { customerName: "", customerMobile: "", pinCode: "" };

    if (!formData.customerName) {
      newErrors.customerName = "Name is required";
      isValid = false;
    }

    if (!validatePhone(formData.customerMobile)) {
      newErrors.customerMobile = "Phone number must be 10 digits";
      isValid = false;
    }

    if (!formData.pinCode) {
      newErrors.pinCode = "Pin code is required";
      isValid = false;
    }

    const pinCodes = staticData?.pinCodes || [];
    if (formData && !pinCodes.includes(formData.pinCode)) {
      newErrors.pinCode = "Sorry we don't provide service in selected location";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleFormSubmit = () => {
    if (handleValidation()) {
      handleOrderNow();
    }
  };

  return (
    !loading && (
      <Flex
        direction="column"
        p={4}
        bg="white"
        width="100%"
        alignItems="center"
        height={"70vh"}
        overflowY="auto"
        borderTopRadius={"lg"}
        border={"1px solid #ddd"}
        boxShadow={"2xl"}
      >
        <Flex width={isMobile ? "100%" : "50%"} direction="column">
          <Flex justifyContent={"right"}>
            <Box
              boxShadow={"md"}
              padding={2}
              borderRadius={"lg"}
              cursor={"pointer"}
            >
              <CloseIcon
                onClick={() => {
                  setModalOpen("A", false);
                  setModalOpen("B", false);
                  setModalOpen("C", false);
                }}
              />
            </Box>
          </Flex>
          <Text fontSize="md" fontWeight="bold" mb={4}>
            Estimated Price: {estimated}
            <span style={{ color: "red" }}>*</span>
          </Text>
          <Text fontSize="md" fontWeight="bold" mb={4}>
            Please confirm your contact details
          </Text>

          <FormControl mb={4} isInvalid={errors.customerName}>
            <FormLabel fontSize="md" fontWeight="bold">
              Name
            </FormLabel>
            <Input
              placeholder="Enter your name"
              value={formData.customerName}
              onChange={(e) => handleChanges("customerName", e.target.value)}
            />
            {errors.customerName && (
              <Text color="red.500" fontSize="sm">
                {errors.customerName}
              </Text>
            )}
          </FormControl>

          <FormControl mb={4} isInvalid={errors.customerMobile}>
            <FormLabel fontSize="md" fontWeight="bold">
              Phone Number
            </FormLabel>
            <Input
              placeholder="Enter your phone number"
              value={formData.customerMobile}
              onChange={(e) => handleChanges("customerMobile", e.target.value)}
            />
            {errors.customerMobile && (
              <Text color="red.500" fontSize="sm">
                {errors.customerMobile}
              </Text>
            )}
          </FormControl>
          <FormControl mb={4} isInvalid={errors.pinCode}>
            <FormLabel fontSize="md" fontWeight="bold">
              Enter your Pin Code
            </FormLabel>
            <Input
              placeholder="Enter your pin code"
              value={formData.pinCode}
              onChange={(e) => handleChanges("pinCode", e.target.value)}
            />
            {errors.pinCode && (
              <Text color="red.500" fontSize="sm">
                {errors.pinCode}
              </Text>
            )}
          </FormControl>

          <Flex justifyContent="space-between">
            <Button colorScheme="blue" mr={3} onClick={handleFormSubmit}>
              Place Order
            </Button>
            <Button
              variant="ghost"
              onClick={() => {
                setModalOpen("A", false);
                setModalOpen("B", true);
                setModalOpen("C", false);
              }}
            >
              Back
            </Button>
          </Flex>
        </Flex>
      </Flex>
    )
  );
};

export default UserDetailsForm;
