import { Box, Flex, Image, Text, useBreakpointValue } from "@chakra-ui/react";
import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles

const ImageGallery = ({ product }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const flexDirection = useBreakpointValue({ base: "column", md: "row" });

  const handleThumbnailClick = (index) => {
    setSelectedIndex(index);
  };

  return (
    <Flex direction={"column"}>
      <Text fontSize={"sm"}>
        Home &gt;{" "}
        {product.sectionHeaders?.[0] || product.dropDowns?.[0] || "Cakes"} &gt;{" "}
        {product.name}
      </Text>
      <Box display="flex" flexDirection={flexDirection} alignItems="flex-start">
        {!isMobile && (
          <Box
            flex="0 0 100px"
            overflowY="auto"
            display="flex"
            flexDirection="column"
            alignItems="center"
            pr={2}
          >
            {product.images && product.images.length > 0 ? (
              product.images.map((image, index) => (
                <Box
                  key={index}
                  style={{ marginBottom: "5px" }}
                  border={"1px solid"}
                  borderRadius={"lg"}
                >
                  <Image
                    src={image.medium}
                    alt={`Thumbnail ${index + 1}`}
                    style={{
                      width: "80px",
                      height: "auto",
                      cursor: "pointer",
                      border:
                        selectedIndex === index
                          ? "2px solid blue"
                          : "2px solid transparent",
                      borderRadius: "5px",
                      transition: "border-color 0.3s ease",
                    }}
                    onClick={() => handleThumbnailClick(index)}
                  />
                </Box>
              ))
            ) : (
              <Text>No thumbnails available</Text>
            )}
          </Box>
        )}
        <Box flex="2" maxW="100%" overflow="hidden">
          <Carousel
            showArrows={true}
            showStatus={false}
            showIndicators={false}
            infiniteLoop
            autoPlay
            emulateTouch
            selectedItem={selectedIndex} // Control carousel item
          >
            {product.images && product.images.length > 0 ? (
              product.images.map((image, index) => (
                <div key={index}>
                  <Image
                    src={image.medium}
                    alt={`Cake ${index + 1}`}
                    style={{
                      width: "100%",
                      borderRadius: "8px",
                    }}
                  />
                </div>
              ))
            ) : (
              <Text>No image available</Text>
            )}
          </Carousel>
        </Box>
        {isMobile && (
          <Box
            flex="0 0 100px"
            overflowX="auto"
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            {product.images && product.images.length > 0 ? (
              product.images.map((image, index) => (
                <Box
                  key={index}
                  style={{ marginBottom: "5px" }}
                  border={"1px solid"}
                  borderRadius={"lg"}
                >
                  <Image
                    src={image.medium}
                    alt={`Thumbnail ${index + 1}`}
                    style={{
                      width: "80px",
                      height: "auto",
                      cursor: "pointer",
                      border:
                        selectedIndex === index
                          ? "2px solid blue"
                          : "2px solid transparent",
                      borderRadius: "5px",
                      transition: "border-color 0.3s ease",
                    }}
                    onClick={() => handleThumbnailClick(index)}
                  />
                </Box>
              ))
            ) : (
              <Text>No thumbnails available</Text>
            )}
          </Box>
        )}
      </Box>
    </Flex>
  );
};

export default ImageGallery;
