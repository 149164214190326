import { Box, Text, useBreakpointValue } from "@chakra-ui/react";
import Marquee from "../components/Common/Marquee";
import FloatingWhatsAppButton from "../components/CustomerFrontComponents/FloatingWhatsappButton";
import Footer from "../components/CustomerFrontComponents/Footer";
import Header from "../components/CustomerFrontComponents/Header";
import Menubar from "../components/CustomerFrontComponents/Menubar";
import ProductComponent from "../components/CustomerFrontComponents/ProductComponent";
import Products from "../components/CustomerFrontComponents/Products";
import SearchProducts from "../components/CustomerFrontComponents/SearchProducts";
import SwiperCollection from "../components/CustomerFrontComponents/SwiperCollection";
import { StaticProvider } from "../context/StaticProvider";

const CustomerFront = ({ mode }) => {
  const gaps = useBreakpointValue({ base: { mx: 0 }, md: { mx: 20 } });

  return (
    <StaticProvider>
      <Box width={"100%"}>
        <Header />
        <Menubar />
        <Marquee />
        <Box mx={gaps.mx} bgColor={"white"}>
          {mode && mode === "home" && <SwiperCollection />}
          {mode && mode === "view" && <ProductComponent />}
          {mode && mode === "search" && <SearchProducts />}
          {mode && mode !== "home" && (
            <Box bgColor={"gray.200"} p={"2"} borderRadius={"lg"}>
              <Text textAlign={"center"} fontSize={"lg"} my="auto">
                Explore Other Items
              </Text>
            </Box>
          )}
          <Products type="allenabled" />
        </Box>

        <FloatingWhatsAppButton />
        <Footer />
      </Box>
    </StaticProvider>
  );
};

export default CustomerFront;
