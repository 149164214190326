import { Box, Text } from "@chakra-ui/react";

const Marquee = ({ props, children }) => {
  return (
    <Box bgColor={"black"} height={6} py="auto" my={0}>
      <marquee>
        <Text
          fontSize={"sm"}
          fontStyle={"italic"}
          bgColor={"black"}
          color={"white"}
        >
          We offer convenient pickup options and delivery within{" "}
          <strong>Pune, Maharshtra</strong> only. Additional charges apply for
          delivery services.
        </Text>
      </marquee>
    </Box>
  );
};

export default Marquee;
